import React, { Component } from "react";
import { Container, Row, Col, Form, Card, Image, Button, InputGroup, FormControl } from "react-bootstrap";
import NavigationBar from "../../NavigationBar/NavigationBar";

import "./EditProfile.css";

class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "Branden",
            lastName: "Brideau",
            email: "branden@brideau.xyz",
            email_visible: true, // If the user wants to show their email.
            logged_user: false, // The current user that is logged in.
        };
    }

    render() {
        return (
            <>
                <NavigationBar />

                <Container fluid>
                    <Row className="edit-profile-padding">
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card className="edit-profile-vertical-center">
                                <Card.Header>Profile Picture</Card.Header>
                                <Card.Body>
                                    <div className="edit-profile-center edit-profile-pad">
                                        <Form>
                                            <Image src="https://s.brideau.xyz/jbbwc.png" roundedCircle height="180px" />
                                        </Form>
                                    </div>

                                    <Col md={{ span: 12 }}>
                                        <div className="edit-profile-center">
                                            <Form.File id="profile-img-form">
                                                <Form.File.Label className="edit-profile-upload-btn btn-primary">Change picture</Form.File.Label>
                                                <Form.File.Input hidden />
                                            </Form.File>
                                        </div>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="edit-profile-padding">
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card>
                                <Card.Header>Profile Information</Card.Header>
                                <Card.Body>
                                    <div>
                                        <Row>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group controlId="formFirstName">
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Edit first name." />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="formLastName">
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control type="text" placeholder="Edit last name." />
                                                        </Form.Group>

                                                        <Button variant="primary" type="button" className="float-right">
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="edit-profile-padding">
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card>
                                <Card.Header>Account Token</Card.Header>
                                <Card.Body>
                                    <div>
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup>
                                                    <FormControl placeholder="Access Token" className="edit-profile-token-blur" disable="true" />
                                                    <InputGroup.Append>
                                                        <Button variant="primary">Copy</Button>
                                                        <Button variant="danger">Reset</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="edit-profile-padding">
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card>
                                <Card.Header>Account Information</Card.Header>
                                <Card.Body>
                                    <div>
                                        <Row>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group controlId="formUsername">
                                                            <Form.Label>Username</Form.Label>
                                                            <Form.Control type="text" placeholder="Edit username." />
                                                        </Form.Group>

                                                        <Form.Group controlId="formPassword">
                                                            <Form.Label>Password</Form.Label>
                                                            <Form.Control type="password" placeholder="Edit password." />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group controlId="formEmail">
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control type="email" placeholder="Edit email address." />
                                                        </Form.Group>
                                                        <Form.Group controlId="formConfirmPassword">
                                                            <Form.Label>Confirm Password</Form.Label>
                                                            <Form.Control type="password" placeholder="Confirm password." />
                                                        </Form.Group>

                                                        <Button variant="primary" type="button" className="float-right">
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default EditProfile;
