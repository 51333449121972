import React, { Component, Fragment, useState } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

import NavigationBar from "../NavigationBar/NavigationBar";
import "./Upload.css";

import Message from "./Message";
import Progress from "./Progress";
import axios from "axios";

const FileUpload = () => {
    const [file, setFile] = useState("");
    const [filename, setFilename] = useState("Choose File");
    const [uploadedFile, setUploadedFile] = useState({});
    const [message, setMessage] = useState("");
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const onChange = (e) => {
		console.log("changed")
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
    };

    const onSubmit = async (e) => {
		console.log("test");
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", file);

        try {
            const res = await axios.post("http://localhost:5000/upl", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                    setUploadPercentage(
                        parseInt(
                            Math.round(
                                (progressEvent.loaded * 100) /
                                    progressEvent.total
                            )
                        )
                    );
                },
            });

            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 10000);

            const { fileName, filePath } = res.data;

            setUploadedFile({ fileName, filePath });

            setMessage("File Uploaded");
        } catch (err) {
            if (err.response.status === 500) {
                setMessage("There was a problem with the server");
            } else {
                setMessage(err.response.data.msg);
            }
            setUploadPercentage(0);
        }
    };

    return (
        <>
            <NavigationBar />
			<Container fluid>
					<Row md={2} className="justify-content-center loginForm-vertical-center">
						<Card className="cardEnveloppe">
							<Row>
								<Col>
									<Alert variant="success">The max file size is currently 1 GB</Alert>
									{message ? <Message msg={message} /> : null}
									
								</Col>
							</Row>
							<Row>
								<Col>
                                <Card id="custom-file-translate-scss" className="card shadow-sm">
										<Card.Body>
											<Row>
												<Col className="col-10">
												<Progress percentage={uploadPercentage} />
												</Col>
												<Col className="col-2 text-center">
													<Button className="btn btn-sm btn-outline-danger btn-block" onClick="Dropzone.instances[0].removeAllFiles(true)" variant="danger">
														<FontAwesomeIcon icon={faFileExcel} inverse />
													</Button>
												</Col>
											</Row>
											<br />
											<Row>
												<Col>
                                                    <Form className="dropzone dz-clickable">
														<Form.Label>{filename}</Form.Label>
                                                        <Form.File  id="formcheck-api-custom"  custom>
                                                        <Form.File.Input onChange={onChange} style={{width: "100%", height:"700%", cursor: "pointer"}} isValid />
                                                        
                                                        </Form.File>
                                                    </Form>
												</Col>
                                            </Row><br/><Button  onClick={onSubmit} variant="success" block>Submit</Button>
										</Card.Body>
									</Card> 
								</Col>
							</Row>
						</Card>
					</Row>
				</Container>
            <Fragment>
                
                {uploadedFile ? (
                    <div className="row mt-5">
                        <div className="col-md-6 m-auto">
                            <h3 className="text-center">
                                {uploadedFile.fileName}
                            </h3>
                            <img
                                style={{ width: "100%" }}
                                src={uploadedFile.filePath}
                                alt=""
                            />
                        </div>
                    </div>
                ) : null}
            </Fragment>
        </>
    );
};

export default FileUpload;
