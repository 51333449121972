import React, { Component } from "react";
import { Container, Row, Col, DropdownButton, Dropdown, Button, Card, Image } from "react-bootstrap";
import NavigationBar from "../NavigationBar/NavigationBar";

import "./Profile.css";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "Branden",
            lastName: "Brideau",
            email: "branden@brideau.xyz",
            email_visible: true, // If the user wants to show their email.
            logged_user: false, // The current user that is logged in.
        };
    }

    render() {
        return (
            <>
                <NavigationBar />
                <Container fluid>
                    <Row>
                        <Col md={{ span: 8, offset: 2 }}>
                            <Card className="profile-vertical-center">
                                <Card.Body>
                                    <div className="profile-center profile-pad">
                                        <form>
                                            <Image src="https://s.brideau.xyz/jbbwc.png" roundedCircle height="180px" />
                                        </form>

                                        <form>
                                            <h3 className="profile-name-top-padding">
                                                {this.state.firstName} {this.state.lastName}
                                            </h3>
                                        </form>

                                        {this.state.email_visible && (
                                            <form>
                                                <span className="text-muted font-size-sm">{this.state.email}</span>
                                            </form>
                                        )}
                                    </div>

                                    <Row>
                                        <Col md={{ span: 12 }}>
                                            <div className="profile-center">
                                                <Button type="button" href="/profile/:username/media" variant="primary">
                                                    View content
                                                </Button>{" "}
                                                {
                                                    // TODO: Only make this button show if the current user logged is the owner of this profile.
                                                }
                                                <Button type="button" href="/profile/:username/edit" variant="primary">
                                                    Edit profile
                                                </Button>{" "}
                                                {
                                                    // TODO: Update admin paths to include current username.
                                                    // TODO: Make sure current user is admin/mod to show this button
                                                }
                                                <DropdownButton style={{ display: "inline" }} id="dropdown-admin-profile-button" variant="danger" title="Administration">
                                                    <Dropdown.Item href="/admin/:username">Modify user</Dropdown.Item>
                                                    <Dropdown.Item href="/admin/:username/blacklist">Blacklist user</Dropdown.Item>
                                                </DropdownButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Profile;
