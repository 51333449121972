import React from 'react';
import {  useState, useEffect } from 'react';
import { Container, Row, Col, DropdownButton, Dropdown, Button, Card, Image } from "react-bootstrap";
import './Gallery.css';
import InfiniteScroll from 'react-infinite-scroller';
import Figure from 'react-bootstrap/Figure'
import { getPhotos } from './requests';
import NavigationBar from '../NavigationBar/NavigationBar';
let page = 0;
function Gallery() {
    const [items, setItems] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [totalHits, setTotalHits] = useState(0);
const getNewPhotos = async () => {
        page++;
        const response = await getPhotos(page);
        setItems(items.concat(response.data.hits));
        setTotalHits(response.data.totalHits);
        setInitialized(true);
    }
useEffect(() => {
        if (!initialized) {
            getNewPhotos();
        }
    });
return (
    <>

    <NavigationBar/>
        <Container fluid className="Gallery">
        <Col md={12}>
            <br/>
            <Card border="dark">
                <br/>
            <InfiniteScroll
                pageStart={page}
                loadMore={getNewPhotos}
                hasMore={totalHits > items.length}
                threshold={100}
            >
                {items.map((i, index) =>
                    <Figure key={index}>
                        <Figure.Image
                            width={window.innerWidth / 4.4}
                            src={i.webformatURL}
                        />
                    </Figure>
                )}
            </InfiniteScroll>
            </Card>
            </Col>
        </Container>
       
        </>
    );
}
export default Gallery;