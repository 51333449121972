import React, { Component } from "react";
import { Button, Navbar, Nav, NavDropdown, Form, FormControl } from "react-bootstrap";

import "./NavigationBar.css";

class NavigationBar extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<>
				<Navbar bg="dark" variant="dark" expand="lg">
					<Navbar.Brand href="/">Chiasson.Cloud</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="mr-auto">
							<Nav.Link href="/login">Login</Nav.Link>
							<Nav.Link href="/register">Register</Nav.Link>
							<NavDropdown title="Testing"  id="basic-nav-dropdown">
								<NavDropdown.Item href="#action/3.1">Upload</NavDropdown.Item>
								<NavDropdown.Item href="#action/3.2">Profile</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
							</NavDropdown>
						</Nav>
						<Form inline>
							<FormControl disabled type="text" placeholder="Search" className="mr-sm-2" />
							<Button disabled variant="outline-success">Search</Button>
						</Form>
					</Navbar.Collapse>
				</Navbar>
			</>
		);
	}
}
export default NavigationBar;
