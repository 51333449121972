import React, { Component } from "react";
import {} from "react-bootstrap";

import cacamiel from "./cacamiel.png"
import { Image } from "react-bootstrap";
import NavigationBar from "../NavigationBar/NavigationBar";
import "./Home.css";


class Home extends Component {
	
	constructor(props) {
		
		super(props);

		this.state = {};
		
	}

	render() {
		function iframe() {
			return {
				__html: '<iframe frameborder="0" allowfullscreen style="position:absolute;left:0;width:100%;height:100%;" src="./Home/index.html" ></iframe>'
			}
		}
		return (
			<>
				<NavigationBar />
				<div className="main">
				<div dangerouslySetInnerHTML={iframe()} />
				</div>
			</>
		);
	}
}
export default Home;
