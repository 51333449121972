// Import all react modules.
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Import bootstrap, this is only need once.
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./components/Home/Home";
import ChiasseTestingFacility from "./components/ChiasseTestingFacility/ChiasseTestingFacility";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Profile from "./components/Profile/Profile";
import EditProfile from "./components/Profile/EditProfile/EditProfile";
import Upload from "./components/Upload/Upload";
import IndexA from "./components/Admin/Index/Index";
import Gallery from "./components/Gallery/Gallery";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route
                        exact
                        path="/ChiasseTestingFacility"
                        component={ChiasseTestingFacility}
                    />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route
                        exact
                        path="/profile/:username"
                        component={Profile}
                    />
                    <Route
                        exact
                        path="/profile/:username/edit"
                        component={EditProfile}
                    />
                    <Route exact path="/upload" component={Upload} />
                    <Route exact path="/admin" component={IndexA} />
                    <Route exact path="/gallery" component={Gallery} />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
