import React, { Component, createRef } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Card,
    Navbar,
    Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Axios from "axios";
import "./Register.css";

class Register extends Component {
    constructor(props) {
        super(props);

        this.firstNameInput = createRef();
        this.lastNameInput = createRef();
        this.emailInput = createRef();
        this.usernameInput = createRef();
        this.passwordInput = createRef();
        this.confirmPasswordInput = createRef();

        this.state = {
            emptyError: "",
            firstNameError: "",
            lastNameError: "",
            usernameError: "",
            emailError: "",
            passwordError: "",
            confirmPasswordError: "",

            firstname: null,
            lastname: null,
            username: null,
            password: null,
            confirmPassword: null,
            email: null,
        };
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value,
        });
    }

    registerSubmit() {
        this.setState({
            emptyError: "",
            firstNameError: "",
            lastNameError: "",
            usernameError: "",
            emailError: "",
            passwordError: "",
            confirmPasswordError: "",
        });

        let emptyErrorMessage;
        let firstNameErrorMessage;
        let lastNameErrorMessage;
        let usernameErrorMessage;
        let emailErrorMessage;
        let passwordErrorMessage;
        let confirmPasswordErrorMessage;

        let firstName = this.firstNameInput.current.value.trim();
        let lastName = this.lastNameInput.current.value.trim();
        let username = this.usernameInput.current.value.trim();
        let email = this.emailInput.current.value.trim();
        let password = this.passwordInput.current.value;
        let confirmPassword = this.confirmPasswordInput.current.value;

        //Check if all fields are filled
        if (
            _.isEmpty(firstName) ||
            _.isEmpty(lastName) ||
            _.isEmpty(username) ||
            _.isEmpty(email) ||
            _.isEmpty(password) ||
            _.isEmpty(confirmPassword)
        ) {
            emptyErrorMessage = "One or more fields are missing";
            this.setState({
                emptyError: emptyErrorMessage,
            });
            return;
        }

        //FirstName checks
        if (!firstName.match(/^[a-zA-Z_]{3,20}$/))
            firstNameErrorMessage =
                "First name must not include numbers or special characters and be between 3 and 20 letters.";
        //LastName checks
        if (!lastName.match(/^[a-zA-Z_]{3,20}$/))
            lastNameErrorMessage =
                "Last name must not include numbers or special characters and be between 3 and 20 letters.";
        //Username checks
        if (!username.match(/^[a-zA-Z0-9_]{3,20}$/))
            usernameErrorMessage =
                "Username must not include special characters and be between 3 and 20 letters..";
        //Email checks
        if (
            !email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        )
            emailErrorMessage = "Email is not valid.";
        //Password checks
        if (password.length < 8 || password.length > 100)
            passwordErrorMessage =
                "Password must be between 8 and 100 characters.";
        if (_.includes(password, " "))
            passwordErrorMessage = "Password must not contain spaces.";
        //Confirm Password checks
        if (password !== confirmPassword)
            confirmPasswordErrorMessage = "Password must match in both fields.";
        if (
            _.isEmpty(emptyErrorMessage) &&
            _.isEmpty(firstNameErrorMessage) &&
            _.isEmpty(lastNameErrorMessage) &&
            _.isEmpty(usernameErrorMessage) &&
            _.isEmpty(emailErrorMessage) &&
            _.isEmpty(passwordErrorMessage) &&
            _.isEmpty(confirmPasswordErrorMessage)
        ) {
			console.log(username);
			console.log(password);
			console.log(email);
			console.log(firstName);
			console.log(lastName);
			Axios.post("http://localhost:5000/register", {
				username: username.toLowerCase(),
				password: password,
				email: email.toLowerCase(),
				firstname: firstName,
				lastname: lastName

			}).then((response)=>
				console.log(response))
            
        } else {
            this.setState({
                emptyError: emptyErrorMessage,
                firstNameError: firstNameErrorMessage,
                lastNameError: lastNameErrorMessage,
                usernameError: usernameErrorMessage,
                emailError: emailErrorMessage,
                passwordError: passwordErrorMessage,
                confirmPasswordError: confirmPasswordErrorMessage,
            });
        }
    }

    render() {
        return (
            <>
                <Navbar bg="dark" expand="lg">
                    <Navbar.Brand href="/" className="text-light bold">
                        <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back
                    </Navbar.Brand>
                </Navbar>
                <Container fluid>
                    <Row className="justify-content-center registerForm-vertical-center">
                        <Col md={3}>
                            <img
                                src="https://img.chiasson.cloud/wAlU7/pegocEDu48.png/raw"
                                width="65%"
                                height="65%"
                            />
                        </Col>
                        <Col md={4}>
                            <Card border="success" bg="light">
                                <Card.Header as="h5">Register</Card.Header>
                                <Card.Body>
                                    {!_.isEmpty(this.state.emptyError) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>{this.state.emptyError}</b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    {!_.isEmpty(this.state.firstNameError) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>
                                                    {this.state.firstNameError}
                                                </b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    {!_.isEmpty(this.state.lastNameError) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>
                                                    {this.state.lastNameError}
                                                </b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    {!_.isEmpty(this.state.usernameError) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>
                                                    {this.state.usernameError}
                                                </b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    {!_.isEmpty(this.state.emailError) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>{this.state.emailError}</b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    {!_.isEmpty(this.state.passwordError) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>
                                                    {this.state.passwordError}
                                                </b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    {!_.isEmpty(
                                        this.state.confirmPasswordError
                                    ) && (
                                        <Form.Group>
                                            <Alert variant="danger">
                                                <b>
                                                    {
                                                        this.state
                                                            .confirmPasswordError
                                                    }
                                                </b>
                                            </Alert>
                                        </Form.Group>
                                    )}
                                    <Form>
                                        <Form.Group controlId="registerForm-Name">
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        First Name:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        ref={this.firstNameInput}
                                                        placeholder="First Name"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>
                                                        Last Name:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        ref={this.lastNameInput}
                                                        placeholder="Last Name"
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="registerForm-Username">
                                            <Form.Label>Username:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                ref={this.usernameInput}
                                                placeholder="Username"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="registerForm-Email">
                                            <Form.Label>Email:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                ref={this.emailInput}
                                                placeholder="Email"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="registerForm-Password">
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        Password:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        ref={this.passwordInput}
                                                        placeholder="Password"
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>
                                                        Confirm Password:
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        ref={
                                                            this
                                                                .confirmPasswordInput
                                                        }
                                                        placeholder="ConfirmPassword"
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Button
                                            variant="primary"
                                            type="button"
                                            onClick={this.registerSubmit.bind(
                                                this
                                            )}
                                            block
                                            size="lg"
                                        >
                                            <b>Register</b>
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}
export default Register;
