import React, { Component } from "react";
import { Alert, Card, Tab, Tabs, Container, Modal, Button, Form, Row, Col } from "react-bootstrap";

import BootStrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import _ from "lodash";

import NavigationBar from "../../NavigationBar/NavigationBar";
import "./Index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditOpen: false,
			firstname: "",
			lastname: "",
			username: "",
			email: "",
			id: "",
			createdAt: "",
			updatedAt: "",

			bannedUsername: "",
			bannedEmail: "",
			bannedId: "",
			bannedTimeleft: "",
			bannedTotaltime: "",
		};

		this.userColumns = [
			{ dataField: "id", text: "Users ID" },
			{ dataField: "firstname", text: "First Name" },
			{ dataField: "lastname", text: "Last Name" },
			{ dataField: "username", text: "Username" },
			{ dataField: "email", text: "Email" },
			{ dataField: "createdAt", text: "Creation Date" },
			{ dataField: "updatedAt", text: "Updated Date" },
			{ dataField: "edit", text: "Edit", sort: false, formatter: this.editRankFormatter, headerAttrs: { width: 50 }, attrs: { width: 50, class: "EditRow" } },
		];
		this.banColumns = [
			{ dataField: "id", text: "Users ID" },
			{ dataField: "username", text: "Username" },
			{ dataField: "email", text: "Email" },
			{ dataField: "timeleft", text: "Time Left" },
			{ dataField: "totaltime", text: "Total Time" },
			{ dataField: "edit", text: "Edit", sort: false, formatter: this.editRankFormatter, headerAttrs: { width: 50 }, attrs: { width: 50, class: "EditRow" } },
		];
		this.userData = [
			{
				id: "0",
				firstname: "Caca",
				lastname: "Miel",
				username: "CacaMiel123",
				email: "cacamiel@gmail.com",
				createdAt: "2021-01-01",
				updatedAt: "2021-01-25",
			},
			{
				id: "1",
				name: "miel",
			},
		];
		this.banData = [
			{
				id: "0",
				username: "CacaMiel123",
				email: "cacamiel@gmail.com",
				timeleft: "4 Days, 2 Hours",
				totaltime: "7 Days",
			},
			{
				id: "1",
				name: "miel",
			},
		];
		this.rowEditEvents = {
			onClick: (e, row, value) => {
				//	console.log(row.id + " " + column.title));
				console.log(value);
				this.openEditModal();
				this.setState({
					firstname: row.firstname,
					lastname: row.lastname,
					username: row.username,
					email: row.email,
					id: row.id,
					createdAt: row.createdAt,
					updatedAt: row.updatedAt,
				});
			},
		};
		this.rowBanEvents = {
			onClick: (e, row, value) => {
				//	console.log(row.id + " " + column.title));
				console.log(value);
				this.openBanModal();
				this.setState({
					bannedUsername: row.username,
					bannedEmail: row.email,
					bannedId: row.id,
					bannedTimeleft: row.timeleft,
					bannedTotaltime: row.totaltime,
				});
			},
		};
		this.openEditModal = () => this.setState({ isEditOpen: true });
		this.closeEditModal = () => this.setState({ isEditOpen: false });
		this.openBanModal = () => this.setState({ isBanOpen: true });
		this.closeBanModal = () => this.setState({ isBanOpen: false });
	}

	editRankFormatter(cell, row, rowIndex, formatExtraData) {
		return (
			<div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
				<FontAwesomeIcon icon={faEdit} />
			</div>
		);
	}

	render() {
		return (
			<>
				<div style={{ backgroundColor: "white" }}>
					<NavigationBar />
					<Tabs defaultActiveKey="dashboard" id="uncontrolled-tab-example">
						<Tab eventKey="dashboard" title="Dashboard"></Tab>

						{
							//USERS
						}

						<Tab eventKey="users" title="Users">
							<Container fluid>
								<br />
								<BootStrapTable keyField="name" data={this.userData} columns={this.userColumns} pagination={paginationFactory()} rowEvents={this.rowEditEvents} />
								<Modal show={this.state.isEditOpen} onHide={this.closeEditModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
									<Modal.Header closeButton>
										<Modal.Title id="contained-modal-title-vcenter">ID: {this.state.id}</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>First Name:</Form.Label>
														<Form.Control type="text" value={this.state.firstname} placeholder="First Name" />
													</Col>
													<Col>
														<Form.Label>Last Name:</Form.Label>
														<Form.Control type="text" value={this.state.lastname} placeholder="Last Name" />
													</Col>
												</Row>
											</Form.Group>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>Username:</Form.Label>
														<Form.Control type="text" value={this.state.username} placeholder="Username" />
													</Col>
													<Col>
														<Form.Label>Email:</Form.Label>
														<Form.Control type="text" value={this.state.email} placeholder="Email" />
													</Col>
												</Row>
											</Form.Group>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>Created At:</Form.Label>
														<Form.Control type="text" disabled value={this.state.createdAt} placeholder="Creation Date" />
													</Col>
													<Col>
														<Form.Label>Updated Date:</Form.Label>
														<Form.Control type="text" disabled value={this.state.updatedAt} placeholder="Updated Date" />
													</Col>
												</Row>
											</Form.Group>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>Is blacklisted?</Form.Label>
														<Form.Check type="checkbox" label="" />
													</Col>
													<Col>
														<Form.Label>Time</Form.Label>
														<Form.Control as="select" className="mr-sm-2" id="inlineFormCustomSelect" custom>
															<option value="0">Choose...</option>
															<option value="1">Permanant</option>
															<option value="2">1 Year</option>
															<option value="3">1 Month</option>
															<option value="4">1 Week</option>
															<option value="5">1 Day</option>
															<option value="6">1 Hour</option>
														</Form.Control>
													</Col>
													<Col>
														<Form.Label>Time Left</Form.Label>
														<Form.Control type="text" disabled value={""} />
													</Col>
												</Row>
											</Form.Group>
										</Form>
									</Modal.Body>
									<Modal.Footer>
										<Button variant="danger" onClick={this.closeEditModal}>
											Close
										</Button>
										<Button variant="success">Apply</Button>
									</Modal.Footer>
								</Modal>

								<br />
							</Container>
						</Tab>

						<Tab eventKey="banlist" title="Ban List">
							{
								//BANLIST
							}
							<Container fluid>
								<br />
								<BootStrapTable keyField="name" data={this.banData} columns={this.banColumns} pagination={paginationFactory()} rowEvents={this.rowBanEvents} />
								<Modal show={this.state.isBanOpen} onHide={this.closeBanModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
									<Modal.Header closeButton>
										<Modal.Title id="contained-modal-title-vcenter">ID: {this.state.bannedId}</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Form>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>Username:</Form.Label>
														<Form.Control type="text" value={this.state.bannedUsername} placeholder="Username" />
													</Col>
													<Col>
														<Form.Label>Email:</Form.Label>
														<Form.Control type="text" value={this.state.bannedEmail} placeholder="Email" />
													</Col>
												</Row>
											</Form.Group>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>Time Left:</Form.Label>
														<Form.Control type="text" disabled value={this.state.bannedTimeleft} placeholder="Time Left" />
													</Col>
													<Col>
														<Form.Label>Total Time:</Form.Label>
														<Form.Control type="text" disabled value={this.state.bannedTotaltime} placeholder="Total Time" />
													</Col>
												</Row>
											</Form.Group>
											<Form.Group>
												<Row>
													<Col>
														<Form.Label>Is blacklisted?</Form.Label>
														<Form.Check type="checkbox" label="" />
													</Col>
					
												</Row>
											</Form.Group>
										</Form>
									</Modal.Body>
									<Modal.Footer>
										<Button variant="danger" onClick={this.closeBanModal}>
											Close
										</Button>
										<Button variant="success">Apply</Button>
									</Modal.Footer>
								</Modal>

								<br />
							</Container>
						</Tab>
						<Tab eventKey="settings" title="Settings">
							{
								//SETTINGS
							}

							<Container fluid>
								<Row className="justify-content-center loginForm-vertical-center">
									<Col md={4}>
										<Card border="success" bg="light">
											<Card.Header as="h5">Settings</Card.Header>
											<Card.Body>
												{!_.isEmpty(this.state.errorMessage) && (
													<Form.Group>
														<Alert variant="danger">
															<b>{this.state.errorMessage}</b>
														</Alert>
													</Form.Group>
												)}

												<Form>
													<Form.Group controlId="loginForm-Username">
														<Row>
															<Col>
																<Form.Label>Enable user quota</Form.Label>
															</Col>
															<Col>
																<Form.Check type="switch" id="custom-switch" />
															</Col>
														</Row>
													</Form.Group>
													<Form.Group controlId="loginForm-Password">
														<Row>
															<Col>
																<Form.Label>Default user quota</Form.Label>
															</Col>
															<Col>
																<Form.Control type="text" placeholder="10GB" />
															</Col>
														</Row>
													</Form.Group>

													<Button variant="success" type="button" block size="lg">
														<b>Submit changes</b>
													</Button>
												</Form>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</Container>
						</Tab>
					</Tabs>
				</div>
			</>
		);
	}
}
export default Index;
