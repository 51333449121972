import React, { Component, createRef } from "react";
import { Container, Row, Col, Form, Button, Card, Navbar, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

import "./Login.css";
import Axios from "axios";

class Login extends Component {
	constructor(props) {
		super(props);
		this.usernameInput = createRef();
		this.passwordInput = createRef();
		this.rememberMeCheck = createRef();
		this.state = {
			errorMessage: "",
		};
	}

	loginSubmit() {

		let username = this.usernameInput.current.value.trim();
        let password = this.passwordInput.current.value.trim();

		this.setState({ errorMessage: "" });
		let finalErrorMessage = "";
		if (_.isEmpty(username)) {
			if (_.isEmpty(finalErrorMessage)) {
				finalErrorMessage = "USERNAME cannot be empty";
			} else {
				finalErrorMessage = finalErrorMessage + " and USERNAME cannot be empty";
			}
		}

		if (_.isEmpty(password)) {
			if (_.isEmpty(finalErrorMessage)) {
				finalErrorMessage = "PASSWORD cannot be empty";
			} else {
				finalErrorMessage = finalErrorMessage + " and PASSWORD cannot be empty";
			}
		}
		if (_.isEmpty(finalErrorMessage)) {
			//Send the data shit thing to the backend
			
			Axios.post("http://localhost:5000/login",{
				username: username.toLowerCase(),
				password: password
			}).then((response)=>
			console.log(response))
		} else {
			this.setState({
				errorMessage: finalErrorMessage,
			});
		}
	}

	render() {
		return (
			<div className="loginForm-overflow-police">
				<Navbar bg="dark" expand="lg">
					<Navbar.Brand href="/" className="text-light bold">
						<FontAwesomeIcon icon={faLongArrowAltLeft} /> Back
					</Navbar.Brand>
				</Navbar>
				<Container fluid>
					<Row className="justify-content-center loginForm-vertical-center">
						<Col md={3}>
							<img alt="" src="https://img.chiasson.cloud/wAlU7/pegocEDu48.png/raw" width="65%" height="65%" />
							
						</Col>
						<Col md={4}>
							<Card border="success" bg="light">
								<Card.Header as="h5">Login</Card.Header>
								<Card.Body>
									{!_.isEmpty(this.state.errorMessage) && (
										<Form.Group>
											<Alert variant="danger">
												<b>{this.state.errorMessage}</b>
											</Alert>
										</Form.Group>
									)}

									<Form>
										<Form.Group controlId="loginForm-Username">
											<Form.Label>Username or Email:</Form.Label>
											<Form.Control type="text" ref={this.usernameInput} placeholder="Username or Email" />
										</Form.Group>
										<Form.Group controlId="loginForm-Password">
											<Form.Label>Password:</Form.Label>
											<Form.Control type="password" ref={this.passwordInput} placeholder="Password" />
										</Form.Group>
										<Form.Group controlId="loginForm-RememberMe">
											<Form.Check ref={this.rememberMeCheck} label="Remember me" />
										</Form.Group>
										<Form.Group controlId="loginForm-btns">
											<Row>
												<Col md={6}>
													<Button type="button" href="" block>
														Forgot Password?
													</Button>
												</Col>

												<Col md={6}>
													<Button type="button" href="../register" block>
														Create an Account
													</Button>
												</Col>
											</Row>
										</Form.Group>

										<Button variant="success" type="button" onClick={this.loginSubmit.bind(this)} block size="lg">
											<b>Login</b>
										</Button>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default Login;
